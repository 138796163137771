import React, { useState } from 'react'
import styleNav from './Navbar.module.css'
import './nav.css'
import { NavLink } from 'react-router-dom'
import SvgCommon from '../common/SvgCommon'
import { NAVBAR_ITEMS, IconTag } from '@src/constants'
import NavRight from './component/NavRight/NavRight'
import clsx from 'clsx'
import NavMobile from './component/NavMobile'
import { useWindowSize } from '@uidotdev/usehooks'

const Navbar = () => {
  // const logoHeader = '/assets/images/Header/logoHeader.svg'
  const [isHovered, setIsHovered] = useState(false)
  const [itemShowNav, setItemShowNav] = useState('')
  const size = useWindowSize()
  const { width } = size

  const handleMouseEnter = (key) => {
    setItemShowNav(key)
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setItemShowNav('')
  }

  const listNav = (items, key) => {
    let item = [...items]

    if (key === 'about') return item

    if (width > 600 && width < 1170) {
      let lastItem = item.pop()
      item.splice(1, 0, lastItem)
    }

    return item
  }

  return (
    <>
      <div className={styleNav.container_nav} id='mainHeader'>
        <div className={styleNav.container_nav_left}>
          <NavLink className={styleNav.logo} to='/'>
            <SvgCommon src='/assets/images/Header/logoHeader.svg' />
            <div className={styleNav.line} />
          </NavLink>
          <div className={styleNav.content_list_items}>
            {NAVBAR_ITEMS?.slice(0, 2).map((items) => (
              <React.Fragment key={items?.key}>
                <div
                  className={clsx(
                    styleNav.item_nav,
                    isHovered && itemShowNav === items?.key ? styleNav.item_nav_avtive : null
                  )}
                  key={items?.key}
                  onMouseEnter={() => handleMouseEnter(items?.key)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className={styleNav.item_nav_title}>{items?.label}</span>
                </div>
                <div
                  className={clsx(
                    styleNav.list_items,
                    styleNav[`list_items_${items?.key}`],
                    isHovered && itemShowNav === items?.key ? styleNav.show_nav : null
                  )}
                  onMouseEnter={() => handleMouseEnter(items?.key)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className={styleNav.list_items_header}>
                    <SvgCommon src={items?.icon} />
                    <span className={styleNav.list_items_title}>{items?.label}</span>
                  </div>
                  <div
                    className={clsx(styleNav.list_items_content, styleNav[`list_items_${items?.key}`])}
                    style={{ gap: items?.key === 'about' ? '12px 24px' : '4px 24px' }}
                  >
                    {listNav(items?.items, items?.key).map((item_nav, index) => {
                      return (
                        <div key={index}>
                          {item_nav?.map((item) => {
                            return (
                              <NavLink
                                className={styleNav.nav_item}
                                key={item?._id}
                                onClick={handleMouseLeave}
                                style={{ height: items?.key === 'about' ? '64px' : '80px' }}
                                to={item?.link}
                              >
                                <div>
                                  <div style={{ height: 32, width: 32 }}>
                                    <SvgCommon height={32} src={item?.icon} width={32} />
                                  </div>
                                </div>
                                <div className={styleNav.nav_item_content}>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                    <span className={styleNav.content_title}>{item?.label}</span>
                                    {item?.type && item?.isVisible ? (
                                      <span className={styleNav.content_type}>
                                        <SvgCommon src={IconTag[item?.type]} />
                                      </span>
                                    ) : null}
                                  </div>
                                  <span className={styleNav.content_desc}>{item?.desc}</span>
                                </div>
                              </NavLink>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <NavRight />
        {/* mobile */}
        <NavMobile />
      </div>
    </>
  )
}

export default Navbar
