/* eslint-disable no-unused-vars */
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import ImageCommon from '../ImageCommon/ImageCommon'
import { Tooltip } from 'antd'
import './GroupImage.css'
import { getImageSource } from '@src/util/utils'
import { SOURCE } from '@src/constants'

const ContainerImg = styled.div`
  transform-style: preserve-3d;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  position: relative;

  .avatar_gr {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: ${(props) => props.option.size}px;
    height: ${(props) => props.option.size}px;
    position: relative;
    outline: 1.4px solid ${(props) => props.option.colorOutline};
  }
  .avatar_gr:not(:first-child) {
    margin-left: -4px;
    transform: rotateY(-1deg);
  }
  .avatar_gr img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .textShowmore {
    display: flex;
    align-items: center;
    margin-left: -4px;
    padding: 2px 6px;
    color: #4a596b;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 24px;
    background: #eff3fa;
    height: ${(props) => props.option.size}px;
    cursor: pointer;
    outline: 1.4px solid rgba(39, 77, 122, 0.1);
  }
`

const GroupImage = memo(
  ({
    images = [],
    options = {
      size: 24,
      colorOutline: '#fafafa',
      maxShow: 1,
      wantShowmore: true
    },
    type = '',
    showMoreRight = false,
    modeContriEdit = false,
    noShowMore = false,
    showInfo = true,
    title = '',
    notNeedLazy = false
  }) => {
    // const [showAllImg, setShowAllImg] = useState(false)

    console.log('images::', images)
    const option = {
      ...options,
      size: options?.size || 24,
      colorOutline: options?.colorOutline || '#fafafa',
      maxShow: options?.maxShow || 1,
      wantShowmore: options?.wantShowmore || true
    }

    const link = (type, slug) => {
      console.log('type::', type)
      switch (type) {
        case 'token':
        case 'project':
          return `/project/${slug}`
        case 'venture':
          return `/organization/${slug}`
        default:
          return `/${type}/${slug}`
      }
    }

    const items = useMemo(() => {
      return images.filter((item, index, self) => index === self.findIndex((t) => t?.id === item?.id))
    }, [images])

    console.log('items::', items)

    return (
      <div style={{ position: 'relative', width: 'max-content', display: 'flex', alignItems: 'center' }}>
        {items.length < 2 ? (
          <>
            {items.slice(0, option.maxShow).map((item, index) => (
              <div
                className='avatar_gr'
                key={index}
                onClick={() => {
                  console.log(item?.type)
                  window.open(`${link(item?.type ? item?.type : type, item?.slug)}`, '_blank')
                }}
              >
                <ImageCommon
                  borderRadius='50%'
                  height={options?.size}
                  name={item?.name || item?.ventures_name || item?.project_name || SOURCE}
                  notNeedLazy={notNeedLazy}
                  showInfo={showInfo}
                  slug={item?.slug}
                  src={getImageSource(item, item?.type ? item?.type : type)}
                  type={item?.type ? item?.type : type}
                  typeShowName={true}
                  width={options?.size}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {noShowMore ? (
              <ContainerImg
                // onMouseEnter={() => setShowAllImg(true)}
                // onMouseLeave={() => setShowAllImg(false)}
                option={option}
                style={{ zIndex: '100' }}
              >
                <>
                  {items?.length < option.maxShow ? null : (
                    <>
                      {showMoreRight && items.length !== option.maxShow && option.wantShowmore ? (
                        <span className='textShowmore'>+{items.length - items.slice(0, option.maxShow).length}</span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {items.slice(0, option.maxShow).map((item, index) => (
                    <span className='avatar_gr' key={index}>
                      <ImageCommon
                        borderRadius='50%'
                        height={options?.size}
                        src={getImageSource(item, item?.type ? item?.type : type)}
                        width={options?.size}
                      />
                    </span>
                  ))}
                  {items?.length < option.maxShow ? null : (
                    <>
                      {!showMoreRight && items.length !== option.maxShow && option.wantShowmore ? (
                        <span className='textShowmore'>+{items.length - items.slice(0, option.maxShow).length}</span>
                      ) : null}
                    </>
                  )}
                </>
              </ContainerImg>
            ) : (
              <Tooltip
                autoAdjustOverflow
                color='#FAFAFA'
                overlayInnerStyle={{ padding: 0 }}
                placement='bottom'
                rootClassName='container_dropdown_info'
                style={{ top: 460 }}
                title={
                  modeContriEdit ? null : (
                    <div className='container_show_all_img'>
                      <div className='show_all_img'>
                        <span className='title_img_group' style={{ padding: '0 0 4px 0', zIndex: 200 }}>
                          {title}
                        </span>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                          {items.map((item, index) => {
                            return (
                              <div
                                className='show_all_img_item'
                                key={index}
                                onClick={() =>
                                  window.open(`${link(item?.type ? item?.type : type, item?.slug)}`, '_blank')
                                }
                              >
                                <ImageCommon
                                  borderRadius='50%'
                                  height={30}
                                  name={item?.name || item?.ventures_name || item?.project_name || SOURCE}
                                  showInfo={showInfo}
                                  slug={item?.slug}
                                  src={getImageSource(item, item?.type ? item?.type : type)}
                                  type={item?.type ? item?.type : type}
                                  typeShowName={true}
                                  width={30}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                }
                trigger='hover'
              >
                <ContainerImg
                  // onMouseEnter={() => setShowAllImg(true)}
                  // onMouseLeave={() => setShowAllImg(false)}
                  option={option}
                  // style={{ zIndex: '50' }}
                >
                  <>
                    {items?.length < option.maxShow ? null : (
                      <>
                        {showMoreRight && items.length !== option.maxShow && option.wantShowmore ? (
                          <span className='textShowmore'>+{items.length - items.slice(0, option.maxShow).length}</span>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {items.slice(0, option.maxShow).map((item, index) => (
                      <span className='avatar_gr' key={index}>
                        <ImageCommon
                          borderRadius='50%'
                          height={options?.size}
                          showInfo={showInfo}
                          src={getImageSource(item, item?.type ? item?.type : type)}
                          width={options?.size}
                        />
                      </span>
                    ))}
                    {items?.length < option.maxShow ? null : (
                      <>
                        {!showMoreRight && items.length !== option.maxShow && option.wantShowmore ? (
                          <span className='textShowmore'>+{items.length - items.slice(0, option.maxShow).length}</span>
                        ) : null}
                      </>
                    )}
                  </>
                </ContainerImg>
              </Tooltip>
            )}
          </>
        )}
      </div>
    )
  }
)
GroupImage.displayName = 'GroupImage'
export default GroupImage
