import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DropdownInfoCommon from '../DropdownInfoCommon/DropdownInfoCommon'
import { useHover } from '@src/hooks/useHover'
import { Tooltip } from 'antd'
import { ALT_IMAGE } from '@src/constants'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: default;
  position: relative;

  .imageWrap img {
    transform-origin: 50% 50%;
    transition: transform 1s, filter 0.5s ease-out;
  }
  .imageWrap {
    height: 10px;
    overflow: hidden;
  }
  .imageWrap {
    width: 100%;
    height: 100%;
  }
  .imageWrap img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform-origin: 50% 50%;
    transition: all 0.2s ease-in-out;
  }
  .imageWrap img.hoveredImage {
    transition: all 0.2s ease-in-out;
    scale: 1.08;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .imageWrap:hover .overlay {
    opacity: 1;
  }
`

const ImageCommon = ({
  src = '',
  imageError = '/ico.svg',
  // alt = '',
  width = 200,
  height = 200,
  borderRadius = 6,
  type = '',
  minHeight,
  scale = false,
  cursor,
  overlayHover,
  slug,
  styleContainer,
  showInfo = true,
  typeShowName = false,
  name = '',
  notNeedLazy = false,
  objectFit = 'cover'
}) => {
  const [imageUrl, setImageUrl] = useState(src)
  const [isError, setIsError] = useState(false)
  const [hoverRef, isHovering] = useHover()

  const handleImageError = (event) => {
    // const target = event.target || {}
    if (event) {
      const target = event.target || {}
      target.src = imageError
    }
    setImageUrl(imageError)
  }

  useEffect(() => {
    if (!src) {
      handleImageError()
      setIsError(true)
    } else {
      setImageUrl(src)
      setIsError(false)
    }
  }, [src])

  return (
    <div style={{ position: 'relative', ...styleContainer }}>
      {type !== '' ? (
        <>
          {showInfo ? (
            <Tooltip
              autoAdjustOverflow
              color='#FAFAFA'
              destroyTooltipOnHide
              mouseEnterDelay={0.3}
              overlayInnerStyle={{ padding: 0 }}
              placement='bottom'
              rootClassName='container_dropdown_info'
              title={<DropdownInfoCommon slug={slug} type={type === 'project' ? 'token' : type} />}
              trigger='hover'
            >
              <ImageContainer
                ref={hoverRef}
                style={{ position: 'relative', width, height: isError ? minHeight || height : height, borderRadius }}
                width={width}
              >
                <Link
                  className='imageWrap'
                  rel='noreferrer'
                  target='_blank'
                  to={
                    type === 'token'
                      ? `/project/${slug}`
                      : type === 'venture'
                      ? `/organization/${slug}`
                      : `/${type}/${slug}`
                  }
                >
                  {notNeedLazy ? (
                    <img
                      alt={`${ALT_IMAGE}'login'`}
                      className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                      height='100%'
                      onError={handleImageError}
                      src={imageUrl}
                      width='100%'
                    />
                  ) : (
                    <LazyLoadImage
                      alt={`${ALT_IMAGE}'login'`}
                      className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                      effect='blur'
                      height='100%'
                      onError={handleImageError}
                      src={imageUrl}
                      width='100%'
                    />
                  )}
                  <div
                    className='overlay'
                    style={
                      overlayHover
                        ? {
                            background: overlayHover
                          }
                        : {}
                    }
                  />
                </Link>
              </ImageContainer>
            </Tooltip>
          ) : (
            <>
              {typeShowName ? (
                <Tooltip
                  autoAdjustOverflow
                  color='#FAFAFA'
                  destroyTooltipOnHide
                  overlayInnerStyle={{ padding: 0 }}
                  placement='bottom'
                  rootClassName='container_dropdown_info'
                  title={<div style={{ padding: 8, color: '#363739', fontSize: 13, fontWeight: 600 }}>{name}</div>}
                  trigger='hover'
                >
                  <ImageContainer
                    ref={hoverRef}
                    style={{
                      position: 'relative',
                      width,
                      height: isError ? minHeight || height : height,
                      borderRadius
                    }}
                    width={width}
                  >
                    <Link
                      className='imageWrap'
                      rel='noreferrer'
                      target='_blank'
                      to={
                        type === 'token'
                          ? `/project/${slug}`
                          : type === 'venture'
                          ? `/organization/${slug}`
                          : `/${type}/${slug}`
                      }
                    >
                      <LazyLoadImage
                        alt={`${ALT_IMAGE}'login'`}
                        className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                        effect='blur'
                        height='100%'
                        onError={handleImageError}
                        src={imageUrl}
                        width='100%'
                      />
                      <div
                        className='overlay'
                        style={
                          overlayHover
                            ? {
                                background: overlayHover
                              }
                            : {}
                        }
                      />
                    </Link>
                  </ImageContainer>
                </Tooltip>
              ) : (
                <ImageContainer
                  ref={hoverRef}
                  style={{ position: 'relative', width, height: isError ? minHeight || height : height, borderRadius }}
                  width={width}
                >
                  <Link
                    className='imageWrap'
                    rel='noreferrer'
                    target='_blank'
                    to={
                      type === 'token'
                        ? `/project/${slug}`
                        : type === 'venture'
                        ? `/organization/${slug}`
                        : `/${type}/${slug}`
                    }
                  >
                    <LazyLoadImage
                      alt={`${ALT_IMAGE}'login'`}
                      className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                      effect='blur'
                      height='100%'
                      onError={handleImageError}
                      src={imageUrl}
                      width='100%'
                    />
                    <div
                      className='overlay'
                      style={
                        overlayHover
                          ? {
                              background: overlayHover
                            }
                          : {}
                      }
                    />
                  </Link>
                </ImageContainer>
              )}
            </>
          )}
        </>
      ) : (
        <ImageContainer
          ref={hoverRef}
          style={{ position: 'relative', width, height: isError ? minHeight || height : height, borderRadius }}
          width={width}
        >
          <span
            className='imageWrap'
            style={{
              cursor: cursor || 'default'
            }}
          >
            {notNeedLazy ? (
              <img
                alt={`${ALT_IMAGE}'login'`}
                className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                height='100%'
                onError={handleImageError}
                src={imageUrl}
                width='100%'
              />
            ) : (
              <LazyLoadImage
                alt={`${ALT_IMAGE}'login'`}
                className={clsx('lazy-load-image-login', isHovering && scale ? 'hoveredImage' : '')}
                effect='blur'
                height='100%'
                onError={handleImageError}
                src={imageUrl}
                style={{ objectFit: objectFit }}
                width='100%'
              />
            )}
            <div
              className='overlay'
              style={
                overlayHover
                  ? {
                      background: overlayHover
                    }
                  : {}
              }
            />
          </span>
        </ImageContainer>
      )}
    </div>
  )
}

export default ImageCommon
